import React, { Component } from 'react';
import './App.css';

import Commands from './Commands';
import HELP from './Commands/HELP';
import download from './Commands/download';

class App extends Component {

  state = {
    history: [],
    history_selected: 0,
    outputs: []
  }

  componentDidMount() {
    const { outputs } = this.state;
    const self = this;
    window.addEventListener('click', function (e) {
      self.refs.cmdline.focus();
    }, false);


    setTimeout(() => {
      outputs.push({ cmd: "start", args: {}, command: "start" });
      self.setState({ outputs });
    }, 400);

    setTimeout(() => {
      outputs.push({ cmd: "help", args: {}, command: "help" });
      self.setState({ outputs });
    }, 1000);
  }

  exec = () => {
    const { history, outputs } = this.state;
    const command = history[history.length - 1];
    if (command.length) {
      let args = command.split(' ').filter(function (val, i) {
        return val;
      });
      const cmd = args[0].toLowerCase();
      args = args.splice(1);
      outputs.push({ cmd, args, command });
      this.setState({ outputs });
    }
  }

  onKeyDown = (e) => {
    const { history, history_selected } = this.state;
    if (history.length) {
      if (e.keyCode == 38) {
        const next = (history_selected - 1);
        const new_selection = (next <= 0 ? 0 : next);
        this.setState({ history_selected: new_selection });
      } else if (e.keyCode == 40) {
        const next = (history_selected + 1);
        const new_selection = (next >= history.length ? history.length : next);
        this.setState({ history_selected: new_selection });
      }
    }

    if (e.keyCode == 9) {
      e.preventDefault();
    } else if (e.keyCode == 13) {
      this.setState({ history_selected: (history_selected + 1) }, this.exec);
    }
  }

  onChange = (e) => {
    const { history, history_selected } = this.state;
    const new_history = [...history];
    if (new_history[history_selected] === undefined) {
      new_history.push("");
    }
    let textTyped = e.target.value
    new_history[history_selected] = textTyped.toLowerCase();
    this.setState({ history: new_history });

  }

  render() {
    const { history, history_selected } = this.state;
    return (
      <div id="container">
        <output>
          {this._renderLogs()}
        </output>
        <div id="input-line" className="input-line">
          <div className="prompt">{window._prompt_name}</div>
          <div>
            <input
              ref="cmdline"
              className="cmdlineInput"
              autofocus
              onKeyDown={this.onKeyDown}
              onChange={this.onChange}
              value={(history[history_selected] ? history[history_selected] : "")}
            />
          </div>
        </div>
      </div>
    );
  }

  _renderLogs = () => {
    const { outputs } = this.state;
    const Unknow = Commands["unknow"];

    return outputs.map(({ cmd, args, command }) => {
      if (cmd === 'clear') {
        this.setState({ outputs: [] })
      }

      if (cmd === "help") {
        return <HELP cmd={cmd} commands={Commands} />;
      }

      if (cmd in Commands) {
        const OutputComponent = Commands[cmd];
        return <OutputComponent args={args} command={command} />;
      }

      return <Unknow cmd={cmd} command={command} />

    });
  }
}

export default App;

