import React, { Component } from "react";

class oldcmd extends Component {

  render() {
    return (
      <div className="input-line">
        <div className="prompt">{window._prompt_name}</div>
        <div>
          <input
            disabled
            className="cmdline"
            value={this.props.command}
          />
        </div>
      </div>
    );
  }
}

export default oldcmd;

