import unknow from "./unknow";
import uname from "./uname";
import about from "./about";
import intro from "./intro";
import start from './start';
import stack from './stack';
import projects from './projects';
import contact from './contact';
import download from './download';

export default {
  start,
  uname,
  unknow,
  about,
  stack,
  projects,
  contact,
  download
}