import React from "react";
import Oldcmd from "./oldcmd";

window.hostname_intro = String(`
░█████╗░██╗░░░░░███████╗██╗░░██╗░░░░░░████████╗███████╗███╗░░██╗░█████╗░██████╗░██╗░█████╗░ ░█████╗░░█████╗░███╗░░██╗░██████╗░█████╗░██╗░░░░░███████╗
██╔══██╗██║░░░░░██╔════╝╚██╗██╔╝░░░░░░╚══██╔══╝██╔════╝████╗░██║██╔══██╗██╔══██╗██║██╔══██╗ ██╔══██╗██╔══██╗████╗░██║██╔════╝██╔══██╗██║░░░░░██╔════╝
███████║██║░░░░░█████╗░░░╚███╔╝░█████╗░░░██║░░░█████╗░░██╔██╗██║██║░░██║██████╔╝██║██║░░██║ ██║░░╚═╝██║░░██║██╔██╗██║╚█████╗░██║░░██║██║░░░░░█████╗░░
██╔══██║██║░░░░░██╔══╝░░░██╔██╗░╚════╝░░░██║░░░██╔══╝░░██║╚████║██║░░██║██╔══██╗██║██║░░██║ ██║░░██╗██║░░██║██║╚████║░╚═══██╗██║░░██║██║░░░░░██╔══╝░░
██║░░██║███████╗███████╗██╔╝╚██╗░░░░░░░░░██║░░░███████╗██║░╚███║╚█████╔╝██║░░██║██║╚█████╔╝ ╚█████╔╝╚█████╔╝██║░╚███║██████╔╝╚█████╔╝███████╗███████╗
`);

const intro = ({ command }) => {
  return (
    <div>
      <Oldcmd command={command} />
      <pre style={{ fontSize: 3 }}>{window.hostname_intro}</pre>
    </div>
  );
}

intro.describe = "intro";

export default intro;
