import React, { Component } from "react";
import Oldcmd from "./oldcmd";

const Unknow = ({ cmd, command }) => {
	return (
		<div>
			<Oldcmd command={command} />
			<p style={{ color: 'red' }}>-bash: {cmd}: command not found, please enter 'help' to show commands 💢</p>
		</div>
	);
}

Unknow.describe = "";

export default Unknow;