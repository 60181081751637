import React from "react";
import Oldcmd from "./oldcmd";

const stack = ({ command, args }) => {
  let contenido = (
    <div>
      <table>
        <tr>
          <th>React Native</th><td><b>█████████████░  -  99%</b></td>
        </tr>
        <tr>
          <th>Swift</th><td><b>███████████░░░  -  80%</b></td>
        </tr>
        <tr>
          <th>Java Android Studio</th><td><b>███████████░░░  -  80%</b></td>
        </tr>
        <tr>
          <th>React.js</th><td><b>████████████░░ - 90%</b></td>
        </tr>
        <tr>
          <th>Redux.js</th><td><b>███████████░░░ - 85%</b></td>
        </tr>
        <tr>
          <th>HTML5</th><td><b>██████████████ - 100%</b></td>
        </tr>
        <tr>
          <th>CSS</th><td><b>██████████████ - 100%</b></td>
        </tr>
        <tr>
          <th>Node.js</th><td><b>██████████░░░░ - 75%</b></td>
        </tr>
        <tr>
          <th>Express.js</th><td><b>████████████░░ - 80%</b></td>
        </tr>
        <tr>
          <th>mongodb</th><td><b>█████████░░░░░ - 70%</b></td>
        </tr>
        <tr>
          <th>git</th><td><b>█████████████░  - 99%</b></td>
        </tr>
        <tr>
          <th>UI/UX</th><td><b>███████████░░░  - 85%</b></td>
        </tr>
        <tr>
          <th>AZURE</th><td><b>████████░░░░░░  - 65%</b></td>
        </tr>
        <tr>
          <th>AWS</th><td><b>█████████░░░░░  - 70%</b></td>
        </tr>
      </table>
    </div>
  );


  return (
    <div>
      <Oldcmd command={command} />
      {contenido}
    </div>
  );
}

stack.describe = "stack - Developer stack";

export default stack;

