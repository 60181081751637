import React from "react";
import Oldcmd from "./oldcmd";

const about = ({ command, args }) => {
  let contenido = (
    <div>
      <table>
        <tr>
          <th>Name</th><td><b>Alejandro Tenorio Camargo</b></td>
        </tr>
        <tr>
          <th>Nationality</th><td><b>Mexican</b></td>
        </tr>
        <tr>
          <th>Profession</th><td><b>Senior Tech Lead / Fullstack</b></td>
        </tr>
        <tr>
          <th>Email</th><td><b>ing.atenciist@gmail.com</b></td>
        </tr>
        <tr>
          <th>Mobile</th><td><b>771-2079073</b></td>
        </tr>
      </table>
    </div>
  );

  if (args.indexOf("social") > -1) {
    contenido = (
      <div>
        <table>
          <tr>
            <th>Facebook</th><td><b><a style={{ color: '#f5c24c' }} href='https://www.facebook.com/alex.mercer.3388630' target='_blank'>ir</a></b></td>
          </tr>
          <tr>
            <th>Twitter</th><td><b><a style={{ color: '#f5c24c' }} href='https://twitter.com/Alexin_Mercer' target='_blank'>ir</a></b></td>
          </tr>
          <tr>
            <th>LinkedIn</th><td><b><a style={{ color: '#f5c24c' }} href='https://www.linkedin.com/in/alexinmercer/' target='_blank'>ir</a></b></td>
          </tr>
          <tr>
            <th>Web</th><td><b><a style={{ color: '#f5c24c' }} href='http://www.knowdevs.com' target='_blank'>ir</a></b></td>
          </tr>
        </table>
      </div>
    );
  }

  if (args.indexOf("skills") > -1) {
    contenido = (
      <div>
        <table>
          <tr>
            <th>Analytic</th><td><b style={{ color: '#4eccc4' }}>█████████████░  -  99%</b></td>
          </tr>
          <tr>
            <th>Proactive</th><td><b style={{ color: '#4eccc4' }}>█████████████░  -  90%</b></td>
          </tr>
          <tr>
            <th>Agile</th><td><b style={{ color: '#4eccc4' }}>█████████████░  -  90%</b></td>
          </tr>
          <tr>
            <th>Responsibility</th><td><b style={{ color: '#4eccc4' }}>██████████████  -  100%</b></td>
          </tr>
          <tr>
            <th>Lead Team</th><td><b style={{ color: '#4eccc4' }}>██████████████ - 100%</b></td>
          </tr>
          <tr>
            <th>Flexibility</th><td><b style={{ color: '#4eccc4' }}>███████████░░░ - 85%</b></td>
          </tr>
          <tr>
            <th>Self-learning</th><td><b style={{ color: '#4eccc4' }}>██████████████ - 100%</b></td>
          </tr>
          <tr>
            <th>Creativity</th><td><b style={{ color: '#4eccc4' }}>███████████░░░ - 85%</b></td>
          </tr>
          <tr>
            <th>Commitment</th><td><b style={{ color: '#4eccc4' }}>██████████████ - 100%</b></td>
          </tr>
        </table>
      </div>
    );
  }

  return (
    <div>
      <Oldcmd command={command} />
      {contenido}
    </div>
  );
}

about.describe = "about [contact|social|skills] [default:contact]";

export default about;

