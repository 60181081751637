import React, { Component } from "react";
import Oldcmd from "./oldcmd";

class download extends Component {

  componentDidMount() {
    window.open('./files/cvalexten.pdf', '_blank');
  }

  render() {
    return (
      <div>
        <Oldcmd command={this.props.command} />
        <pre>{'CV Downloaded'}</pre>
      </div>
    );
  }

}

download.describe = "download - CV download";

export default download;
