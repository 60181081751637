import React, { Component } from "react";
import Oldcmd from "./oldcmd";

class contact extends Component {

  componentDidMount() {
    window.open('https://wa.me/527712079073/?text=Hola', '_blank');
  }

  render() {
    return (
      <div>
        <Oldcmd command={this.props.command} />
        <pre>{'Contacto'}</pre>
      </div>
    );
  }

}

contact.describe = "contact - Contact Alex 😱";

export default contact;
