import React from "react";
import Oldcmd from "./oldcmd";

const HELP = ({ commands, cmd }) => {
  const msgs = [
    <Oldcmd command={cmd} />
  ];
  const keys = Object.keys(commands);
  msgs.push(<p>{'help - to show commands'}</p>);

  keys.map((key) => {
    msgs.push(<p>{commands[key].describe}</p>);
  });
  msgs.push(<p>{'clear - To clean terminal'}</p>);

  return (
    <div>
      {msgs}
    </div>
  );
}

export default HELP;