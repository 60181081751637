import React from "react";
import Oldcmd from "./oldcmd";

const uname = ({ command }) => {
  console.log(window.navigator)
  return (
    <div>
      <Oldcmd command={command} />
      <p>{window.navigator.appVersion}</p>
    </div>
  );
}

uname.describe = "uname";

export default uname;

