import React from "react";
import Oldcmd from "./oldcmd";

window.hostname_intro = String(`

                                                     @@@@@@                @@@@@@               
                                                   @@@     @@@@        @@@@     @@@             
                                                  @@          @@@    @@@          @@            
                                                  @@             @@@@             @@            
                                                  @@             @@@@             @@            
                                                  @@           @@    @@           @@            
                                                  @@         @@@  @@  @@@         @@            
                                                   @@  @@@@@@@@@@@  @@@@@@@@@@@  @@             
                                                @@@@@@    @@@            @@@    @@@@@@          
                                            @@@     @@    @@              @@    @@     @@@      
                                         @@@         @@ @@@      @@@@      @@@ @@         @@@   
                                        @@           @@@@      @@@@@@@@      @@@@           @@  
                                       @@             @@@     @@@@@@@@@@     @@@             @@ 
                                        @@           @@@@      @@@@@@@@      @@@@           @@  
                                         @@@         @@ @@@      @@@@      @@@ @@         @@@   
                                            @@@     @@    @@              @@    @@     @@@      
                                                @@@@@@    @@@            @@@    @@@@@@          
                                                   @@  @@@@@@@@@@@  @@@@@@@@@@@  @@             
                                                  @@         @@@  @@  @@@         @@            
                                                   @@           @@    @@           @@            
                                                   @@             @@@@             @@            
                                                   @@             @@@@             @@            
                                                  @@          @@@    @@@          @@            
                                                    @@@     @@@@        @@@@     @@@             
                                                      @@@@@@                @@@@@@               


░█████╗░██╗░░░░░███████╗██╗░░██╗░░░░░░████████╗███████╗███╗░░██╗░█████╗░██████╗░██╗░█████╗░░░░░░░░█████╗░░█████╗░███╗░░██╗░██████╗░█████╗░██╗░░░░░███████╗
██╔══██╗██║░░░░░██╔════╝╚██╗██╔╝░░░░░░╚══██╔══╝██╔════╝████╗░██║██╔══██╗██╔══██╗██║██╔══██╗░░░░░░██╔══██╗██╔══██╗████╗░██║██╔════╝██╔══██╗██║░░░░░██╔════╝
███████║██║░░░░░█████╗░░░╚███╔╝░█████╗░░░██║░░░█████╗░░██╔██╗██║██║░░██║██████╔╝██║██║░░██║█████╗██║░░╚═╝██║░░██║██╔██╗██║╚█████╗░██║░░██║██║░░░░░█████╗░░
██╔══██║██║░░░░░██╔══╝░░░██╔██╗░╚════╝░░░██║░░░██╔══╝░░██║╚████║██║░░██║██╔══██╗██║██║░░██║╚════╝██║░░██╗██║░░██║██║╚████║░╚═══██╗██║░░██║██║░░░░░██╔══╝░░
██║░░██║███████╗███████╗██╔╝╚██╗░░░░░░░░░██║░░░███████╗██║░╚███║╚█████╔╝██║░░██║██║╚█████╔╝░░░░░░╚█████╔╝╚█████╔╝██║░╚███║██████╔╝╚█████╔╝███████╗███████╗


`);

const start = ({ command }) => {
  return (
    <div>
      <Oldcmd command={command} />
      <pre>{'WELCOME TO ALEJANDRO TENORIO TERMINAL V 1.0.2 '}</pre>
      <pre style={{ fontSize: 4 }}>{window.hostname_intro}</pre>
      <pre>{'PLEASE TYPE help TO SEE THE COMMANDS '}</pre>
    </div>
  );
}

start.describe = "start - Show message initial";

export default start;
