import React from "react";
import Oldcmd from "./oldcmd";

window.projects = String(`
|-2020
|&nbsp;&nbsp;|_Portal Coronavirus Gob. Hidalgo - EJS && React.js
|&nbsp;&nbsp;|_APP TutorahTv - React-Native 
|&nbsp;&nbsp;|&nbsp;&nbsp;|_
|&nbsp;&nbsp;|_Portal Empleo Gob. Hidalgo - EJS 
|&nbsp;&nbsp;|_APP Hidalgo Mágico - React-Native & API-Server
|&nbsp;&nbsp;|_APP TUZOBUS - React-Native & API-Server
|-2019
|&nbsp;&nbsp;|_APP eSIT Finanzas - React-Native
|&nbsp;&nbsp;|_Web Gob. Hidalgo - EJS && HTML5
|-2018
|&nbsp;&nbsp;|_Portal Tramites y Servicios Gob. Hidalgo - React.js
|&nbsp;&nbsp;|_App Contraloria - React-Native & API-Server


`);

const projects = ({ command, args }) => {
  let contenido = (
    <div>
      <p>{window.projects}</p>
    </div>
  );


  return (
    <div>
      <Oldcmd command={command} />
      <div>|_2021</div>
      <div>|&nbsp;&nbsp;|_🌐 Director de gobierno Digital</div>
      <div>|_2020</div>
      <div>|&nbsp;&nbsp;|_🌐 Portal Coronavirus Gob. Hidalgo</div>
      <div>|&nbsp;&nbsp;|&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='http://coronavirus.hidalgo.gob.mx' target="_blank">Portal Coronavirus</a></div>
      <div>|&nbsp;&nbsp;|_APP 📱 TutorahTv - React-Native </div>
      <div>|&nbsp;&nbsp;|&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='https://play.google.com/store/apps/details?id=com.kimosolutions.tutorahtv' target="_blank">Android</a></div>
      <div>|&nbsp;&nbsp;|&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='https://apps.apple.com/us/app/tutorah-tv/id1422159803' target="_blank">iOS</a></div>
      <div>|&nbsp;&nbsp;|_🌐 Portal Empleo Gob. Hidalgo</div>
      <div>|&nbsp;&nbsp;|&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='http://empleo.hidalgo.gob.mx' target="_blank">Portal Empleo</a></div>
      <div>|&nbsp;&nbsp;|_APP 📱 Hidalgo Mágico - React-Native & API-Server </div>
      <div>|&nbsp;&nbsp;&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='https://play.google.com/store/apps/details?id=com.hidalgo_magico&hl=es_MX' target="_blank">Android</a></div>
      <div>|&nbsp;&nbsp;&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='https://apps.apple.com/us/app/hidalgo-magico/id1485107365?l=es&ls=1' target="_blank">iOS</a></div>

      <div>|-2019</div>
      <div>|&nbsp;&nbsp;|_APP 📱 Tuzobús - React-Native & API-Server </div>
      <div>|&nbsp;&nbsp;|&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='https://play.google.com/store/apps/details?id=com.tuzobus' target="_blank">Android</a></div>
      <div>|&nbsp;&nbsp;|&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='https://apps.apple.com/mx/app/tuzob%C3%BAs/id1473326651' target="_blank">iOS</a></div>
      <div>|&nbsp;&nbsp;|_APP 📱 MADSKILLS - React-Native</div>
      <div>|&nbsp;&nbsp;|_APP 📱 eSIT - React-Native</div>
      <div>|&nbsp;&nbsp;&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='https://apps.apple.com/mx/app/esit-m%C3%B3vil/id1443970983' target="_blank">iOS</a></div>

      <div>|-2018</div>
      <div>|&nbsp;&nbsp;|_🌐 Portal Tramites y Servicios Gob Hidalgo</div>
      <div>|&nbsp;&nbsp;|&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='http://ruts.hidalgo.gob.mx' target="_blank">Portal Tramites y Servicios</a></div>
      <div>|&nbsp;&nbsp;|_🌐 Web Gob. Hidalgo - EJS && HTML5</div>
      <div>|&nbsp;&nbsp;|&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='https://www.hidalgo.gob.mx' target="_blank">Portal Gob. Hidalgo</a></div>
      <div>|&nbsp;&nbsp;|_App 📱 Contraloria - React-Native & API-Server</div>
      <div>|&nbsp;&nbsp;|&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='https://play.google.com/store/apps/details?id=com.contraloria' target="_blank">Android</a></div>
      <div>|&nbsp;&nbsp;|&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='https://itunes.apple.com/mx/app/contraloria-hgo/id1321244009?mt=8' target="_blank">iOS</a></div>
      <div>|&nbsp;&nbsp;|_APP 📱 QLUB - React-Native</div>
      <div>|&nbsp;&nbsp;&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='https://play.google.com/store/apps/details?id=com.milista' target="_blank">Android</a></div>
      <div>|&nbsp;&nbsp;&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='https://itunes.apple.com/mx/app/qlub/id1313537105?mt=8' target="_blank">iOS</a></div>

      <div>|-2017</div>
      <div>|&nbsp;&nbsp;|_APP 📱 BeepQuest - React-Native</div>
      <div>|&nbsp;&nbsp;&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='https://play.google.com/store/apps/developer?id=BeepQuest&hl=es' target="_blank">Android</a></div>
      <div>|&nbsp;&nbsp;&nbsp;&nbsp;|_<a style={{ color: '#f5c24c' }} href='https://itunes.apple.com/es/developer/gquest-s-a-de-c-v/id991564912' target="_blank">iOS</a></div>

      <div>|-2016</div>
      <div>|&nbsp;&nbsp;|_📱 Wander2.0 - Swift</div>

      <div>|-2015</div>
      <div>|&nbsp;&nbsp;|_APP 📱 Monster Class - Swift, Java</div>
      <div>|&nbsp;&nbsp;|_APP 📱 Ecomodel - Swift, Java</div>


    </div>
  );
}

projects.describe = "projects - Show most important developed projects";

export default projects;
//<pre style={{ fontSize: 15 }}>{window.projects}</pre>
